import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`According to Gartner, customer service chatbots are a technology to watch. `}<a parentName="p" {...{
        "href": "https://www.gartner.com/smarterwithgartner/4-trends-gartner-hype-cycle-customer-service-customer-engagement/"
      }}>{`In two to five years`}</a>{`, Gartner analysts predict that chatbots are going to reach the `}<a parentName="p" {...{
        "href": "https://www.gartner.com/en/research/methodologies/gartner-hype-cycle"
      }}>{`Plateau of Productivity`}</a>{`. You know that automation is an extremely promising solution to an increasingly high volume of support tickets. By deploying a chatbot, you’d be able to cut down the amount of busy work your agents do while also giving customers the option of 24/7 support for simple questions and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/24/customer-service-chatbots-providing-an-effective-solution/"
      }}>{`improving the user experience`}</a>{`. But before jumping right in and developing a chatbot, it’s important to take stock of your resources to see how automation might fit into your current customer support strategy.`}</p>
    <h2>{`Automate what’s possible`}</h2>
    <p>{`Step 1 is simple: identify channels you currently have open that can serve as a frame of reference. This is any method where business communications with customers take place. For example, you could look into your Interactive Voice Response (IVR), live agent through chat or phone, emails, or Facebook Messenger. Anywhere there’s dialogue with customers is fair game. From there, evaluate which channels handle the most traffic, since you want to bring the automation to where your customers naturally want to go. Pull data and historical live agent transcripts from your busiest channels to determine the amount of `}<a parentName="p" {...{
        "href": "https://www.bmc.com/blogs/support-levels-level-1-level-2-level-3/"
      }}>{`level 1 support`}</a>{` questions each one handles. Examples of level 1 support questions can include FAQ’s and requesting tracking information, tasks that don’t require more than five message turns to complete.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Pull live agent transcripts from your busiest channels to identify level 1 support questions”`}</p>
      <footer>
  [ Tweet
  ](https://twitter.com/intent/tweet?text=%22Pull+live+agent+transcripts+from+your+busiest+channels+to+identify+level+1+support+questions%22&url=https%3A%2F%2Fwww.dashbot.io%2Fdashcms%2Fwp-admin%2Fadmin-ajax.php)
      </footer>
    </blockquote>
    <p>{`You’ll find it helpful here to run analytics on the data to determine the market share of your channels. If you have a lot of data and transcripts to process, the process might get bottlenecked. Sheer volume of historical transcripts can be overwhelming, so consider running `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/train/"
      }}>{`Phrase Clustering`}</a>{` to identify and group common messages and intents to keep the project running smoothly.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/14163403/funnels-1-1024x655.png",
        "alt": "identify level 1 support questions for customer service chatbot"
      }}></img></p>
    <p>{`Automate What’s Possible`}</p>
    <hr></hr>
    <p>{`Now that you’ve identified a channel ready for automation and a list of common intents, it’s time to decide what low-hanging support fruit you’ll solve with your chatbot. With chatbots, it’s vital to keep expectations low. Start with a simple task before diving into a more complex use case later on. For example, level 1 support issues like a password reset or `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/10/03/applying-data-science-to-customer-service-chatbots/"
      }}>{`tracking a package`}</a>{` that can easily be resolved in a couple message turns are prime use cases.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/14163549/automation-300x235.png",
        "alt": "automate what's possible with customer service chatbots"
      }}></img>{`Starting small is extremely important to a sustainable, functional chatbot and managing user expectations. Overpromising from the start will hurt brand image and your customers’ trust in you. When the chatbot fails to resolve a complex and potentially aggravating issue, your `}<a parentName="p" {...{
        "href": "https://blog.emolytics.com/customer-experience/customer-satisfaction-score-csat-kpi/"
      }}>{`CSAT score`}</a>{` suffers. Making big promises but not delivering will leave your customers unhappy with not only their experience, but your company as well.`}</p>
    <p>{`By keeping expectations low, customers are more likely to leave delighted and feeling like the chatbot was a better experience than a live agent. Moreover, you’ll be able to gather information and learn from these simple interactions. Should you choose to scale your chatbot in the future or cover more complex questions, you’ll have a wealth of information ready at your disposal to inform future development.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/14163719/Group-1024x791.png",
        "alt": "examples of good customer service chatbots vs bad"
      }}></img></p>
    <h2>{`Determine ROI`}</h2>
    <p>{`In order to get the other stakeholders in your company onboard, its vital to show that the investment necessary to build a chatbot will result in some ROI. In many cases, building and maintaining a chatbot will save the company money.`}</p>
    <p>{`Start by figuring out how much time on average an agent spends on each ticket and the percentage of tickets that can be classified as level 1. An agent’s time is valuable and has legitimate monetary value. By determining how much it’s costing the company for live agents to handle simple support questions that could be automated, you can show your stakeholders a very valid reason why a chatbot is the right financial move to make.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/14163740/chalkboard.png",
        "alt": "use this question to calculate ROI"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/14163807/iceberg-300x281.png",
        "alt": "benefits of chatbots besides financial "
      }}></img>{`Finances are just the tip of the iceberg. Yes, chatbots will give your budget a little more breathing room, but your customers will be the greatest beneficiary. If level 1 inquires are being quickly solved through automation, then agents will be able to devote more time to addressing the more nuanced support tickets. Simply put, your support center will be able to provide higher quality care to even more customers with the same amount of agents. It’s a recipe for increased ROI and a higher CSAT score.`}</p>
    <p><em parentName="p">{`This is part 1 of Dashbot’s series on chatbot development. `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/2019/10/22/customer-service-chatbots-building-your-mvp/"
        }}>{`Read part 2 here. `}</a></em></p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+chatbot+development"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      